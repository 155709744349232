import React from "react"
import { Helmet } from "react-helmet"
import styled, { css } from "styled-components"
import { up } from "styled-breakpoints"

import Layout from "../components/layout"
import Form from "../components/form"
import Text from "../components/text"

const Wrapper = styled.div`
  display: grid;
`

const Container = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  padding: 0 2rem;
  -webkit-font-smoothing: antialiased;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    margin: 0 auto;
    grid-template-columns: 960px;
  }
`

const GetInTouch = () => {
  return (
    <Layout minimal>
      <Helmet>
        <title>Paybase - Request access</title>
        <meta property="og:title" content="Paybase - Request access" />
      </Helmet>
      <Wrapper>
        <Container>
          <div
            css={`
              display: grid;
              grid-gap: 1rem;
            `}
          >
            <Text type="headline" align="center" weight="bold">
              Working on a product?
            </Text>
            <Text type="h4" align="center">
              Request sandbox access!
            </Text>
          </div>
          <Form btnCopy="Request access" type="get-in-touch-sandbox" />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default GetInTouch
